@import  "~primeicons/primeicons.css";
@import  "~primeng/resources/themes/saga-blue/theme.css";
@import  "~primeng/resources/primeng.min.css";
@import  "~primeflex/primeflex.css";

body {
    margin-left: 0px;
    margin-right: 0px;
    background-color: rgb(255, 255, 255);
    max-width: 100%;
    font-family: FordAntenna !important;
    
}

html {
    margin-left: 0px;
    margin-right: 0px;
}

.p-dialog .p-dialog-header {
	background-color: darkgreen;
}


p-datatable {
    background-color: #fafafa;
    color: black;
    padding: .571em .35em;
    text-align: center;
}

.p-accordion .p-accordion-header .p-accordion-header-link, .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: darkgreen;
    padding: 7px;
    font-size: 14px;
    font-weight: 400;
}
.p-accordion .p-accordion-content {
    padding: 0%;
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin: 6px;
}

form {
    box-shadow: 0 1px 10px #ccc;
    padding: 5px;;
}
.p-datatable .p-datatable-thead > tr > th, .p-datatable .p-datatable-tbody > tr > td, .p-datatable .p-datatable-thead > tr > th:last-child {
    padding: 5px;
    text-align: center;
    border: solid #b8b2b2;
}

.p-card .p-card-body {
    padding: 0.5em;
}

.p-component {
    font-family: FordAntenna !important;
    font-size: 14px;
}
.p-dialog-header {
	color: white !important;
}

.p-dialog .p-dialog-header {
    padding: 2%;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    color: white;
}

.p-sortable-column-badge {
    display: none !important;
}

.p-dialog .p-dialog-content {
    padding: 1em 2em;;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    margin-bottom: auto;
    margin-left: auto;
  }

.oneoff-form-element {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-bottom: 1em;
}

.oneoff-form-label {
    text-align: left;
    width: 18em;
}

.oneoff-form-input {
    text-align: left;
    width: 18em;
}
   

@media (max-width: 1300px) {
    .empty-column {
       display: none;
    }
    .p-col-3 {
        margin-left: 6em;
        width: 40%;
    }
    .oneoff-form-element {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .oneoff-form-element {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}
@media (max-width: 800px) {
    .empty-column {
        display: none;
    }
    .p-col-3 {
        width: 100%;
    }
    .oneoff-form-element {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .oneoff-form-element {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}